<template>
    <div >
       <div class="curContainer">
            <div  width="100%">
        <el-row type="flex" justify="end">
            <!-- <el-button type="primary" size="small" icon="el-icon-download" @click.stop="onExportClick()">添加</el-button> -->
        <!-- <el-button type="primary" @click="onItemNew()" style="margin-right:10px;">新建</el-button> -->
      </el-row>
    <el-form inline :model="productSearchForm" label-width="140px" style="margin-top: 30px;">
      
      <el-form-item label="选择线路">
        <el-cascader
            :options="juDuanSuoList"
            :show-all-levels="false"    
            :props="props"
            v-model="productSearchForm.stationArray"
            collapse-tags
            @change="onStationXmlChange(stationArray=$event)"
            clearable></el-cascader>
      </el-form-item>
      <el-form-item label="关键字">
        <el-input v-model="productSearchForm.key" autocomplete="off"></el-input>
      </el-form-item>
      <el-button @click="onProductSearch" type="primary">搜索</el-button>
    </el-form>

    <!-- <common-table :data="moniDataList"> -->
    <common-table :data="Media_TrainList" border :header-cell-style="{background:'#eef1f6',color:'#606266'}"
			style="width: 100%;margin-top: 10px;" ref="Table">
      <!-- <el-table-column prop="luju" align="center" label="路局"  width="80" ></el-table-column>
      <el-table-column prop="dongCheDuan" align="center"  width="120" label="动车段" ></el-table-column>
      <el-table-column prop="yunXingXianLu" align="center"  width="120" label="运行线路" ></el-table-column>
      <el-table-column prop="cheCi" align="center"  width="80" label="车次" ></el-table-column>
      <el-table-column prop="shiFaZhongDao" align="center" width="120"  label="始发终到站点" ></el-table-column>
     <el-table-column prop="shiFaShiJian" align="center" width="80"  label="始发时间" ></el-table-column>
     <el-table-column prop="zhongDaoShiJian" align="center" width="80"  label="终到时间" ></el-table-column>
     <el-table-column prop="meiZuLieShu" align="center" width="80"  label="每组列数" ></el-table-column>
     <el-table-column prop="jingTingCheZhan" align="center" label="经停车站" ></el-table-column>
     <el-table-column prop="yunXingQingKuang" align="center" width="80"  label="运行情况" ></el-table-column>
     <el-table-column prop="shuoMing" align="center" width="150"  label="说明" ></el-table-column> -->
     <el-table-column prop="luju" align="center" label="路局"  ></el-table-column>
      <el-table-column prop="dongCheDuan" align="center"  label="动车段" ></el-table-column>
      <el-table-column prop="dongCheSuo" align="center"   label="所" ></el-table-column>
      <el-table-column prop="cheXing" align="center"  width="130" label="车型" ></el-table-column>
      <el-table-column prop="mediaType" align="center" width="160"  label="媒体形式" ></el-table-column>

     <!-- <el-table-column prop="zhandiandengji" align="center" label="刊例价(万元/月/组)" ></el-table-column> -->
      <!-- <el-table-column  align="center" label="操作" >
        <template slot-scope="scope">
          <el-button type="primary" size="small"
            @click.native.prevent="onItemEditClick(scope.row)"
          >修改</el-button>
           <el-button type="danger" size="small"
            @click.native.prevent="onItemDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column> -->
    <!-- </common-table> -->
    </common-table>
    <!-- <edit-brand-dialog :visible.sync="addNewDialogVisible" :current-item="{}"/> -->
    <!-- <edit-brand-dialog :visible.sync="addNewDialogVisible" :current-item="currentItem" :curType="1"/> -->
            </div>
       </div>
    </div>
</template>

<script>
import CommonTable from "@/components/CommonTable";
// import {getLatestMonthDateArrayByDate} from "@/utils/time";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/auth";
// import {dealDeleteOperation} from "@/utils/tools";
// import {delBrand} from "@/api/auditing";
// import EditBrandDialog from "@/views/Operate/Brand/Market/EditBrandDialog";


export default {
  name: "Advertiser",
  components: { CommonTable}, 
  computed: { ...mapGetters(["lujuXianluList","Media_TrainList",'juDuanSuoList']) },
  data() {
    return {      
      props: { multiple: true },

      addNewDialogVisible: false,
      productSearchForm: {
        key: "",
        // dateArray: getLatestMonthDateArrayByDate({date:new Date(),delayNum:1, formatValue: 'yyyy-MM-dd'}),
        stationArray:[],
        stationStr:'',
      },
      currentItem: {},
    };
  },
  async created() {
    // await this.$store.dispatch("auditing/jclxList", {});
    // await this.$store.dispatch("auditing/sjztList", {});
    // await this.$store.dispatch('operate/lujuXianluList');
    await this.$store.dispatch('operate/juDuanSuoList');
    
    await this.onProductSearch();
  },
  methods: {    
    onStationXmlChange(event) {
			// console.log('onStationXmlArrayChange', this.stationArray);
      var statStr ='';
      this.productSearchForm.stationArray.forEach(element => {
        statStr=statStr+element[2]+','
      })
      this.productSearchForm.stationStr=statStr;
					// this.$emit('change', this.stationXmlArray);
	},

    async onItemEditClick(item) {
			// console.log('item',item);
			this.currentItem = item;
			this.addNewDialogVisible = true;
	},
      
    async onItemNew(){
        this.currentItem = {};
	    this.addNewDialogVisible = true;
    },

    async onItemDelete(item) {
		console.log(item);
		await dealDeleteOperation({deletePromise:delBrand({userId: getToken(), brandId: item.brandId,type:'1'})})
		// await this.$store.dispatch('dataInterface/DataInterfaceList');
        await this.onProductSearch();
	},


    async onProductSearch() {
      console.log(this.productSearchForm);
 
      await this.$store.dispatch("operate/Media_TrainList", {
        // sDate:sDate,
        // eDate:eDate,
        stationIds:this.productSearchForm.stationStr,
        key: this.productSearchForm.key,
      }).then(res=>{
          // this.$refs.Table.doLayout();
      });

    },
  },
};
</script>

<style scoped>
.error{
    color: red;
}
.ok{
    color: green;
}
.curContainer {
    /* padding: 30px; */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}
</style>