<template>
    <div >
       <div class="curContainer">
            <div  width="100%">
        <el-row type="flex" justify="end">
            <!-- <el-button type="primary" size="small" icon="el-icon-download" @click.stop="onExportClick()">添加</el-button> -->
        <!-- <el-button type="primary" @click="onItemNew()" style="margin-right:10px;">新建</el-button> -->
      </el-row>
    <el-form inline :model="productSearchForm" label-width="140px" style="margin-top: 30px;">
      
      <el-form-item label="选择类型">
        <el-cascader
            :options="lieCheInnerJiaGeLeiXingList"
            :show-all-levels="false"    
            :props="props"
            v-model="productSearchForm.stationArray"
            collapse-tags
            @change="onStationXmlChange(stationArray=$event)"
            clearable></el-cascader>
      </el-form-item>
      <el-form-item label="关键字">
        <el-input v-model="productSearchForm.key" autocomplete="off"></el-input>
      </el-form-item>
      <el-button @click="onProductSearch" type="primary">搜索</el-button>
    </el-form>

    <!-- <common-table :data="moniDataList"> -->
    <common-table :data="InnerTrainPriceList" border :header-cell-style="{background:'#eef1f6',color:'#606266'}"
			style="width: 100%;margin-top: 10px;" ref="Table">
      <el-table-column prop="trainTypeorLuJu" align="center" label="类型"  width="80" ></el-table-column>
      <el-table-column prop="priceName" align="center"  width="120" label="媒体类型" ></el-table-column>
      <el-table-column prop="monthPrice" align="center"  width="120" label="月刊例" ></el-table-column>
      <el-table-column prop="seasonPrice" align="center"  width="80" label="季刊例" ></el-table-column>
      <el-table-column prop="halfYearPrice" align="center" width="120"  label="半年刊例" ></el-table-column>
     <el-table-column prop="yearPrice" align="center" width="80"  label="年刊例" ></el-table-column>
     <el-table-column prop="oncePrice" align="center" width="80"  label="制作安装费" ></el-table-column>
     <el-table-column prop="remark1" align="center"  label="备注1" ></el-table-column>
     <el-table-column prop="remark2" align="center" label="备注2" ></el-table-column>
     
    </common-table>
            </div>
       </div>
    </div>
</template>

<script>
import CommonTable from "@/components/CommonTable";
// import {getLatestMonthDateArrayByDate} from "@/utils/time";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/auth";



export default {
  name: "Advertiser",
  components: { CommonTable}, 
  computed: { ...mapGetters(["lieCheInnerJiaGeLeiXingList","InnerTrainPriceList"]) },
  data() {
    return {      
      props: { multiple: true },

      addNewDialogVisible: false,
      productSearchForm: {
        key: "",
        // dateArray: getLatestMonthDateArrayByDate({date:new Date(),delayNum:1, formatValue: 'yyyy-MM-dd'}),
        stationArray:[],
        trainTypeOrLuJu:'',
        priceName:'',
      },
      currentItem: {},
    };
  },
  async created() {
    // await this.$store.dispatch("auditing/jclxList", {});
    // await this.$store.dispatch("auditing/sjztList", {});
    await this.$store.dispatch('operate/lieCheInnerJiaGeLeiXingList');
    await this.onProductSearch();
  },
  methods: {    
    onStationXmlChange(event) {
			// console.log('onStationXmlArrayChange', this.stationArray);
      var pStr ='';
      var tStr='';
      this.productSearchForm.stationArray.forEach(element => {
          tStr=tStr+element[0]+',';
        pStr=pStr+element[1]+',';
      })
      this.productSearchForm.trainTypeOrLuJu=tStr;
      this.productSearchForm.priceName=pStr;
					// this.$emit('change', this.stationXmlArray);
	},

    async onItemEditClick(item) {
			// console.log('item',item);
			this.currentItem = item;
			this.addNewDialogVisible = true;
	},
      
    async onItemNew(){
        this.currentItem = {};
	    this.addNewDialogVisible = true;
    },

    async onItemDelete(item) {
		console.log(item);
		await dealDeleteOperation({deletePromise:delBrand({userId: getToken(), brandId: item.brandId,type:'1'})})
		// await this.$store.dispatch('dataInterface/DataInterfaceList');
        await this.onProductSearch();
	},


    async onProductSearch() {
      console.log(this.productSearchForm);
 
      await this.$store.dispatch("operate/InnerTrainPriceList", {
        // sDate:sDate,
        // eDate:eDate,
        trainTypeOrLuJu:this.productSearchForm.trainTypeOrLuJu,
        priceName:this.productSearchForm.priceName,
        key: this.productSearchForm.key,
      }).then(res=>{
          // this.$refs.Table.doLayout();
      });

    },
  },
};
</script>

<style scoped>
.error{
    color: red;
}
.ok{
    color: green;
}
.curContainer {
    /* padding: 30px; */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}
</style>