<template>
  <div  style="width:100%;">	
	<div  style="margin-top: 0px;margin-left:0px;background-color:#fff;border-radius:15px">
		<el-row type="flex">
			<el-tabs v-model="activeName" @tab-click="handleClick" style="margin-left:10px;">
				<el-tab-pane label="列车资源" name="first"></el-tab-pane>
				<el-tab-pane label="列车内媒体刊例" name="second"></el-tab-pane>
				<el-tab-pane label="列车电视刊例" name="third"></el-tab-pane>
			</el-tabs>
		</el-row>
	</div>
	<media-train-source v-if="isSourceView"/>
	<media-train-inner-price v-if="isInnerPriceView"/>
	<media-train-tv-price v-if="isTvPriceView"/>
  </div>
</template>

<script>
import MediaTrainSource from "@/views/Business/Media_train_source";
import MediaTrainInnerPrice from "@/views/Business/Media_train_inner_price";
import MediaTrainTvPrice from "@/views/Business/Media_train_tv_price";

export default {
		name: "SaleJC",
		components: {MediaTrainSource, MediaTrainInnerPrice,MediaTrainTvPrice},
		watch: {
				// isSourceView(value) {
				// 	if (value) {
				// 		this.isInnerPriceView = false;
				// 		this.isMeiTiFenXi = false;
				// 		this.isBrandView = false;
				// 		this.onAnalysis();
				// 	}
				// },
				// isInnerPriceView(value) {
				// 	if (value) {
				// 		this.isSourceView = false;
				// 		this.isMeiTiFenXi = false;
				// 		this.isBrandView = false;
				// 		this.onAnalysis();
				// 	}
				// },
				// isTvPriceView(veiw){
				// 	if (value) {
				// 		this.isSourceView = false;
				// 		this.isMeiTiFenXi = false;
				// 		this.isInnerPriceView = false;
				// 		this.onAnalysis();
				// 	}
				// },
			
		},
		data() {
				return {
						activeName:'first',
						isSourceView: true,
						isInnerPriceView: false,
						isTvPriceView:false,				

				};
		},
		created() {
			// if (this.activeName === 'first'){
			// 	this.onAnalysis(1);
			// }
		},
		methods: {
				// onAnalysis() {
				// 		console.log(this.searchForm);
				// 		const {dateArray: [sYM, eYM],mediumIds='0',gradeId=0, ggzkey = '',ppkey='',strict=0} = this.searchForm;
				// 		if (this.isShiChangView) {
				// 				this.$store.dispatch('analysis/Analisy_Market', {sYM, eYM});
				// 				this.$store.dispatch('analysis/Analisy_Market_Increase', {sYM, eYM});
				// 				this.$store.dispatch('analysis/Analisy_Market_AdDistribute', {sYM, eYM});
				// 		} 
				// 		if (this.isGuangGaoZhuView){
				// 			if (this.searchForm.ggzkey) {
				// 				this.$store.dispatch('analysis/Analisy_MarketAdvertiser', {sYM, eYM, key:ggzkey,strict});
				// 				this.$store.dispatch('analysis/Analisy_MarketAdvertiser2', {sYM, eYM, key:ggzkey,strict});
				// 			}
				// 		}
				// 		if (this.isBrandView){
				// 			if (this.searchForm.ppkey) {
				// 				this.$store.dispatch('analysis/Analisy_MarketBrand', {sYM, eYM, key:ppkey,strict});
				// 				this.$store.dispatch('analysis/Analisy_MarketBrand2', {sYM, eYM, key:ppkey,strict});
								
				// 			}
				// 		}
				// 		if (this.isMeiTiFenXi){
				// 			if (this.searchForm.ppkey) {
				// 				this.$store.dispatch('analysis/Analisy_MarkeMedia', {sYM, eYM,mediumIds,gradeId, key:ppkey,strict});
				// 			}
				// 		}

				// },
				handleClick(tab, event) {
					console.log('tab', tab,tab.props.name);
					if (tab.props.name==='first'){
						this.isSourceView=true;
						this.isInnerPriceView=false;
						this.isTvPriceView=false;
					}
					if (tab.props.name==='second'){
						this.isSourceView=false;
						this.isInnerPriceView=true;
						this.isTvPriceView=false;
					}
					if (tab.props.name==='third'){
						this.isSourceView=false;
						this.isInnerPriceView=false;
						this.isTvPriceView=true;
					}

				},

			// async onListItemExport() {
      //           // await this.$store.dispatch('makePlan/setPlanId', item.planId);
      //           const {dateArray: [sYM, eYM],mediumIds='0',gradeId=0, key = '',strict=0} = this.searchForm;
      //           await this.$store.dispatch('analysis/Analisy_MarkeMediaExportList',
      //           	{sYM, eYM,mediumIds,gradeId, key,strict});
      //       },
		}
}
</script>

<style scoped>

</style>